import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Pagine pubbliche */
import Login from './pages/login';
import Registrazione from './pages/registrazione';
import Profilo from './pages/profilo';

/* Pagine utente */
import Home from './pages/home';
import Cassa from './pages/cassa';
import Bar from './pages/bar';
import Guardaroba from './pages/guardaroba';

/* Pagine admin */
import HomeAdmin from './pages/home-admin';
import CassaAdmin from './pages/cassa-admin';
import BarAdmin from './pages/bar-admin';
import GuardarobaAdmin from './pages/guardaroba-admin';

/* Pagine god */
import God from './pages/god';

const ProtectedRoute = ({ isAuthenticated, children }) => {
    if (!isAuthenticated) {
        return <Navigate to="/user/Login" replace />;
    }
    return children;
};

const App = () => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const updateAuthenticated = (value) => {
        setAuthenticated(value);
    };

    return (
        <Router>
            <Routes>
                {/* Pagine pubbliche */}
                <Route path="/user/Login" element={<Login updateAuthenticated={updateAuthenticated} />} />
                <Route path="/user/Registrazione" element={<Registrazione />} />
                <Route path="/user/Profilo/:idGiocatore" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Profilo /></ProtectedRoute>} />

                {/* Pagine utente */}
                <Route path="/user/Home/:idGiocatore" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Home /></ProtectedRoute>} />
                <Route path="/user/Cassa/:idGiocatore" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Cassa /></ProtectedRoute>} />
                <Route path="/user/Bar/:idGiocatore" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Bar /></ProtectedRoute>} />
                <Route path="/user/Guardaroba/:idGiocatore" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Guardaroba /></ProtectedRoute>} />

                {/* Pagine admin */}
                <Route path="/admin/HomeAdmin/:idGiocatore" element={<ProtectedRoute isAuthenticated={isAuthenticated}><HomeAdmin /></ProtectedRoute>} />
                <Route path="/admin/CassaAdmin" element={<ProtectedRoute isAuthenticated={isAuthenticated}><CassaAdmin /></ProtectedRoute>} />
                <Route path="/admin/BarAdmin" element={<ProtectedRoute isAuthenticated={isAuthenticated}><BarAdmin /></ProtectedRoute>} />
                <Route path="/admin/GuardarobaAdmin" element={<ProtectedRoute isAuthenticated={isAuthenticated}><GuardarobaAdmin /></ProtectedRoute>} />

                {/* Pagine god */}
                <Route path="/god" element={<ProtectedRoute isAuthenticated={isAuthenticated}><God /></ProtectedRoute>} />

                {/* Route di default */}
                <Route path="*" element={<Navigate to="/user/Login" replace />} />
            </Routes>
        </Router>
    );
};

export default App;
