import React from 'react';
import '../styles/home.css';
import Image from '../images/casino-scene.jpg';
import { useNavigate, useParams } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();
    const { idGiocatore } = useParams(); 

    return (
        <div className="container">
            <div className="left">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => navigate(`/user/Login`)}
                >
                    Login
                </button>
                <button className="btn btn-primary" onClick={() => navigate(`/user/Profilo/${idGiocatore}`)}>Profilo</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Casinò</h5>
                <div className="innerContainer">
                    <div className="inner-innerContainer-left">
                        <div className="btn btn-primary" onClick={() => navigate(`/user/Cassa/${idGiocatore}`)}>Cassa</div>
                        <div className="btn btn-primary" onClick={() => navigate(`/user/Bar/${idGiocatore}`)}>Bar</div>
                        <div className="btn btn-primary" onClick={() => navigate(`/user/Guardaroba/${idGiocatore}`)}>Guardaroba</div>
                    </div>
                    <div className="inner-innerContainer-main">
                        <div className="inner-innerContainer-right">
                            <img src={Image} alt="Casino Scene" />
                        </div>
                        <div className="inner-innerContainer-bottom">
                            <p className="bottom-text">hai effettuato l'accesso come: Utente</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
