import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/profilo.css';
import { useState, useEffect } from 'react';
import { getGiocatoreById, aggiornaPassword, updateGiocatore } from '../api/index';

const Profilo = () => {
    const navigate = useNavigate();
    const { idGiocatore } = useParams();
    const [password, setPassword] = useState('');
    const [nuovaPassword, setNuovaPassword] = useState('');
    const [nuovaPassword2, setNuovaPassword2] = useState('');
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [eta, setEta] = useState('');
    const [indirizzo, setIndirizzo] = useState('');

    const caricaGiocatore = async () => {
        try {
            const response = await getGiocatoreById(idGiocatore);
            setNome(response.data.nome);
            setCognome(response.data.cognome);
            setEta(response.data.eta);
            setIndirizzo(response.data.indirizzo);
        } catch (error) {
            console.error('Errore durante il caricamento del giocatore', error);
        }
    }
    useEffect(() => {
        caricaGiocatore();
    }, []);

    const modificaPassword = async () => {
        if (nuovaPassword !== nuovaPassword2) {
            alert('Le password non corrispondono');
            return;
        }
        try {
            const response = await aggiornaPassword({ idGiocatore: idGiocatore, passwordVecchia: password, passwordNuova: nuovaPassword });
            if (response.status === 200) {
                alert('Password modificata con successo');
            }
        } catch (error) {
            console.error('Errore durante la modifica della password', error);
        }
    }

    const aggiornaInformazioni = async () => {
        try {
            const response = await updateGiocatore({ id: idGiocatore, nome: nome, cognome: cognome, eta: eta, indirizzo: indirizzo });
            if (response.status === 200) {
                alert('Informazioni aggiornate con successo');
            }
        } catch (error) {
            console.error('Errore durante l\'aggiornamento delle informazioni', error);
        }
    }
    
    return (
        <div className="container">
            <div className="left">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Home</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Profilo utente</h5>
                <div className="innerContainer">
                    <div className="column-container">
                        <div className="center-container">
                            <div className="row-container">
                                <div className="cassa-admin-left-container">
                                    <div className="column-container">
                                        <div className="cassa-admin-left-innerContainer">
                                            <div className="column-container">
                                                <div className="cassa-admin-title">Informazioni utente:</div>
                                                <div className="cassa-admin-lista-utenti">
                                                    <div className="column-container">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-profilo"
                                                            placeholder="nome"
                                                            aria-label="Nome"
                                                            value={nome}
                                                            onChange={(e) => setNome(e.target.value)}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-profilo"
                                                            placeholder="cognome"
                                                            aria-label="Cognome"
                                                            value={cognome}
                                                            onChange={(e) => setCognome(e.target.value)}
                                                        />
                                                        <input
                                                            type="number"
                                                            className="form-control form-control-profilo"
                                                            placeholder="età"
                                                            aria-label="Età"
                                                            value={eta}
                                                            onChange={(e) => setEta(e.target.value)}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-profilo"
                                                            placeholder="indirizzo"
                                                            aria-label="Indirizzo"
                                                            value={indirizzo}
                                                            onChange={(e) => setIndirizzo(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn btn-primary aggiorna-informazioni" onClick={() => aggiornaInformazioni()}>Aggiorna informazioni</div>
                                    </div>
                                </div>
                                <div className="cassa-admin-left-container">
                                    <div className="column-container">
                                        <div className="cassa-admin-left-innerContainer">
                                            <div className="column-container">
                                                <div className="cassa-admin-title">Password:</div>
                                                <div className="cassa-admin-lista-utenti">
                                                    <div className="column-container">
                                                        <input
                                                            type="password"
                                                            className="form-control form-control-profilo"
                                                            placeholder="Vecchia password"
                                                            aria-label="Password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <input
                                                            type="password"
                                                            className="form-control form-control-profilo"
                                                            placeholder="Nuova password"
                                                            aria-label="Password"
                                                            value={nuovaPassword}
                                                            onChange={(e) => setNuovaPassword(e.target.value)}
                                                        />
                                                        <input
                                                            type="password"
                                                            className="form-control form-control-profilo"
                                                            placeholder="Ripeti nuova password"
                                                            aria-label="Password"
                                                            value={nuovaPassword2}
                                                            onChange={(e) => setNuovaPassword2(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn btn-primary modifica-password" onClick={() => modificaPassword()}>Modifica password</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profilo;
