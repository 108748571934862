import React from 'react';
import '../styles/bar-admin.css';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getDrinks, deleteDrink, aggiungiDrink, updateDrink } from '../api/index';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Cassa = () => {
    const navigate = useNavigate();
    const [drinks, setDrinks] = useState([]);
    const [selectedDrink, setSelectedDrink] = useState('')
    const [successful, setSuccessful] = useState('');
    const [error, setError] = useState('');
    const [nomeNuovoDrink, setNomeNuovoDrink] = useState('');
    const [prezzoNuovoDrink, setPrezzoNuovoDrink] = useState('');
    const [nuovoPrezzoDrink, setNuovoPrezzoDrink] = useState('');
    const [drinkDaModificare, setDrinkDaModificare] = useState('');

    useEffect(() => {
        const timer = setTimeout(() => {
            if (error) {
                setError('');
            }
            if (successful) {
                setSuccessful('');
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [error, successful]);

    const handleSelectedDrink = (username) => {
        setSelectedDrink(username);
        console.log('selectedDrink: ', username);
    }

    const caricaDrinks = async () => {
        try {
            const response = await getDrinks();
            setDrinks(response.data);
        } catch (error) {
            setError(error.message);
        }
    }
    useEffect(() => {
        caricaDrinks();
    }, []);

    const eliminaDrink = async () => {
        try {
            const response = await deleteDrink(selectedDrink);
            if (response.status === 200) {
                setSuccessful('Drink eliminato');
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error.message);
        }
        caricaDrinks();
    }

    const creaDrink = async () => {
        try {
            const response = await aggiungiDrink({ nome: nomeNuovoDrink, prezzo: prezzoNuovoDrink });
            if (response.status === 200) {
                setSuccessful('Drink aggiunto');
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error.message);
        }
        caricaDrinks();
    }

    const modificaDrink = async () => {
        try {
            const response = await updateDrink({ nome: drinkDaModificare, prezzo: nuovoPrezzoDrink });
            if (response.status === 200) {
                setSuccessful('Drink modificato');
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error.message);
        }
        caricaDrinks();
    }

    return (
        <div className="container">
            <div className="left">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Home</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Bar - admin</h5>
                <div className="innerContainer-bar">
                    <div className="row-container">
                        <div className="container-list">
                            <div className="column-container">
                                <div className="list-title">Listino drink</div>
                                <div className="list-box-container">
                                    <div className="list-group" >
                                        {drinks.map((drinks) => (
                                            <label className="list-group-item" key={drinks.nome}>
                                                <input className="form-check-input me-1" type="radio" name="option" value="" onClick={() => handleSelectedDrink(drinks.nome)} />
                                                {drinks.nome} {drinks.prezzo} €
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                {error && (
                                    <div className="alert alert-danger bar-admin-error-message" role="alert">
                                        {error}
                                    </div>
                                )}
                                {successful && (
                                    <div className="alert alert-success bar-admin-success-message" role="alert">
                                        {successful}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="container-right-bar">
                            <div className="column-container">

                                <div className="btn btn-primary bar-admin-aggiungi" data-bs-toggle="modal" data-bs-target="#aggiungiModal">Aggiungi</div>
                                <div className="modal fade" id="aggiungiModal" tabindex="-1" aria-labelledby="aggiungiModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="aggiungiModalLabel">Aggiungi drink</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="nomeDrink"
                                                        placeholder="inserisci nome drink"
                                                        onChange={(e) => { setNomeNuovoDrink(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="prezzoDrink"
                                                        placeholder="inserisci prezzo drink"
                                                        onChange={(e) => { setPrezzoNuovoDrink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary bottone-salva-bar-admin"
                                                    onClick={() => creaDrink()}
                                                >
                                                    Aggiungi
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn btn-primary bar-admin-elimina" onClick={() => eliminaDrink()}>Elimina</div>
                                <div className="btn btn-primary bar-admin-modifica" data-bs-toggle="modal" data-bs-target="#modificaModal">Modifica</div>
                                <div className="modal fade" id="modificaModal" tabindex="-1" aria-labelledby="modificaModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="modificaModalLabel">Modifica drink</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="nomeDrink"
                                                        placeholder={selectedDrink}
                                                        onChange={(e) => { setDrinkDaModificare(e.target.value) }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="prezzoDrink"
                                                        placeholder="inserisci prezzo drink"
                                                        onChange={(e) => { setNuovoPrezzoDrink(e.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary bottone-salva-bar-admin"
                                                    onClick={() => modificaDrink()}
                                                >
                                                    Modifica
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cassa;
