import React from 'react';
import '../styles/cassa.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { paga, pagaFiches, aggiungiSoldi, aggiungiFiches, getGiocatoreById } from '../api/index';

const Cassa = () => {
    const navigate = useNavigate();
    const { idGiocatore } = useParams();

    const [successful, setSuccessful] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
            if (error) {
                setError('');
            }
            if (successful) {
                setSuccessful('');
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [error, successful]);

    const [importoSoldiFiche, setImportoSoldiFiche] = useState();
    const handleConvertiSoldiFiche = async () => {
        try {
            if (importoSoldiFiche === undefined) {
                setError('Inserisci un importo');
                return;
            }
            if (importoSoldiFiche <= 0) {
                setError('L\'importo non può essere negativo');
                return;
            }
            const pagamentoResponse = await paga({ id: idGiocatore, importo: importoSoldiFiche });
            if (pagamentoResponse.status === 200) {
                const aggiuntaFichesResponse = await aggiungiFiches({ id: idGiocatore, importo: importoSoldiFiche });
                if (aggiuntaFichesResponse.status === 200) {
                    setSuccessful('Soldi convertiti in fiches');
                } else {
                    setError(aggiuntaFichesResponse.error);
                }
            } else {
                setError(pagamentoResponse.error);
            }
        } catch (error) {
            setError(error.message);
        }
        caricaDati();
    };

    const [importoFicheSoldi, setImportoFicheSoldi] = useState();
    const handleConvertiFicheSoldi = async () => {
        try {
            if (importoFicheSoldi === undefined) {
                setError('Inserisci un importo');
                return;
            }
            if (importoFicheSoldi <= 0) {
                setError('L\'importo non può essere negativo');
                return;
            }
            const pagamentoResponse = await pagaFiches({ id: idGiocatore, importo: importoFicheSoldi });
            if (pagamentoResponse.status === 200) {
                const aggiuntaFichesResponse = await aggiungiSoldi({ id: idGiocatore, importo: importoFicheSoldi });
                if (aggiuntaFichesResponse.status === 200) {
                    setSuccessful('Fiches convertite in soldi');
                } else {
                    setError(aggiuntaFichesResponse.error);
                }
            } else {
                setError(pagamentoResponse.error);
            }
        } catch (error) {
            setError(error.message);
        }
        caricaDati();
    }

    const [importoSoldi, setImportoSoldi] = useState();
    const handleAggiungiSoldi = async () => {
        try {
            if (importoSoldi === undefined) {
                setError('Inserisci un importo');
                return;
            }
            if (importoSoldi <= 0) {
                setError('L\'importo non può essere negativo');
                return;
            }
            if (importoSoldi > 10000000) {
                setError('L\'importo non può maggiore di 10 milioni di euro');
                return;
            }
            const aggiuntaSoldiResponse = await aggiungiSoldi({ id: idGiocatore, importo: importoSoldi });
            if (aggiuntaSoldiResponse.status === 200) {
                setSuccessful('Soldi aggiunti');
            } else {
                setError(aggiuntaSoldiResponse.error);
            }
        } catch (error) {
            setError(error.message);
        }
        caricaDati();
    }

    const [soldi, setSoldi] = useState(0);
    const [fiches, setFiches] = useState(0);
    const caricaDati = async () => {
        try {
            const response = await getGiocatoreById(idGiocatore);
            setSoldi(response.data.soldi);
            setFiches(response.data.fiches);
        } catch (error) {
            setError(error.message);
        }
    };
    caricaDati();
    
    return (
        <div className="container">
            <div className="left">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Home</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Cassa - utente</h5>
                <div className="innerContainer">
                    <div className="column-container">
                        <div className="row-container">
                            <div className="cassa-wrap-column-container">
                                <div className="cassa-left-container">
                                    <div className="cassa-left-title">Converti soldi in fiches</div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Inserisci l'importo"
                                        value={importoSoldiFiche}
                                        onChange={(e) => setImportoSoldiFiche(e.target.value)}
                                    />
                                    <div className="btn btn-primary" onClick={() => handleConvertiSoldiFiche()}>Converti</div>
                                </div>
                            </div>
                            <div className="cassa-wrap-column-container">
                                <div className="cassa-center-container">
                                    <div className="cassa-center-title">Converti fiches in soldi</div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Inserisci l'importo"
                                        value={importoFicheSoldi}
                                        onChange={(e) => setImportoFicheSoldi(e.target.value)}
                                    />
                                    <div className="btn btn-primary" onClick={() => handleConvertiFicheSoldi()}>Converti</div>
                                </div>
                            </div>
                            <div className="cassa-wrap-column-container">
                                <div className="cassa-right-container">
                                    <div className="cassa-right-title">Ricarica soldi</div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Inserisci l'importo"
                                        value={importoSoldi}
                                        onChange={(e) => setImportoSoldi(e.target.value)}
                                    />
                                    <div className="btn btn-primary" onClick={() => handleAggiungiSoldi()}>Aggiungi</div>
                                </div>
                            </div>
                        </div>
                        <div className="center-container error-container">
                            {error && (
                                <div className="alert alert-danger cassa-error-message" role="alert">
                                    {error}
                                </div>
                            )}
                            {successful && (
                                <div className="alert alert-success cassa-success-message" role="alert">
                                    {successful}
                                </div>
                            )}
                        </div>
                        <p className="soldi-text">Soldi: {soldi}, Fiches: {fiches}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cassa;
