import React from 'react';
import '../styles/bar.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getGiocatoreById, getDrinks, paga } from '../api/index';

const Cassa = () => {
    const navigate = useNavigate();
    const { idGiocatore } = useParams();

    const [ordine, setOrdine] = useState([]);
    const aggiungiAlOrdine = (nomeDrink) => {
        if (!ordine.includes(nomeDrink)) {
            setOrdine([...ordine, nomeDrink]);
        }
    };
    const rimuoviDalOrdine = (nomeDrink) => {
        setOrdine(ordine.filter(nome => nome !== nomeDrink));
    };

    const ordina = async () => {
        var importoTotale = 0;
        drinks.forEach((drink) => {
            if (ordine.includes(drink.nome)) {
                importoTotale += drink.prezzo;
            }
        });
        try {
            const response = await paga({ id: idGiocatore, importo: importoTotale });
            if (response.status === 200) {
                setSuccessful('Ordine effettuato');
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error.message);
        }
        caricaDati();
    };

    const ritira = () => {
        setOrdine([]);
        var checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        setSuccessful('Ordine ritirato');
    };

    const [drinks, setDrinks] = useState([]);
    useEffect(() => {
        const caricaDrinks = async () => {
            try {
                const response = await getDrinks();
                setDrinks(response.data);
            } catch (error) {
                setError(error.message);
            }
        };
        caricaDrinks();
    }, []);

    const [successful, setSuccessful] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
            if (error) {
                setError('');
            }
            if (successful) {
                setSuccessful('');
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [error, successful]);

    const [soldi, setSoldi] = useState(0);
    const [fiches, setFiches] = useState(0);
    const caricaDati = async () => {
        try {
            const response = await getGiocatoreById(idGiocatore);
            setSoldi(response.data.soldi);
            setFiches(response.data.fiches);
        } catch (error) {
            setError(error.message);
        }
    };
    caricaDati();

    return (
        <div className="container">
            <div className="left">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Home</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Bar - utente</h5>
                <div className="innerContainer-bar">
                    <div className="row-container">
                        <div className="container-list">
                            <div className="column-container">
                                <div className="list-title">Listino drink</div>
                                <div className="list-box-container">

                                    <div className="list-group" >
                                        {drinks.map((drinks) => (
                                            <label className="list-group-item" key={drinks.nome}>
                                                <input className="form-check-input me-1" type="checkbox" value=""
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            aggiungiAlOrdine(drinks.nome);
                                                        } else {
                                                            rimuoviDalOrdine(drinks.nome);
                                                        }
                                                    }}
                                                />
                                                {drinks.nome} {drinks.prezzo} €
                                            </label>
                                        ))}
                                    </div>

                                </div>
                                <p className="soldi-text">Soldi: {soldi}, Fiches: {fiches}</p>
                            </div>
                        </div>
                        <div className="container-right-bar">
                            <div className="column-container">
                                <div className="btn btn-primary ordina" onClick={() => ordina()}>Ordina e paga</div>
                                <div className="container-ordinazione">
                                    <div className="list-title">Ordine drink</div>
                                    <div className="column-container">
                                        <div className="list-box-container">
                                            <ul className="list-group">
                                                {ordine.map((ordine) => (
                                                    <li className="list-group-item">{ordine}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        {error && (
                                            <div class="alert alert-danger bar-error-message" role="alert">
                                                {error}
                                            </div>
                                        )}
                                        {successful && (
                                            <div class="alert alert-success bar-success-message" role="alert">
                                                {successful}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="btn btn-primary ritira" onClick={() => ritira()}>Ritira</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cassa;
