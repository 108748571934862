import React, { useState, useRef } from 'react';
import '../styles/login.css';
import '../styles/global.css';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/index';

const Login = ({ updateAuthenticated }) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async () => {
        try {
            const response = await login({ username, password });
            updateAuthenticated(true);
            const userData = response.data[0];
            if (userData.permessi === 'admin') {
                navigate(`/admin/HomeAdmin/${userData.idGiocatore}`);
            } else if (userData.permessi === 'god') {
                navigate(`/god`);
            } else {
                navigate(`/user/Home/${userData.idGiocatore}`);
            }
        } catch (error) {
            setError('Errore durante il login. Verifica le credenziali e riprova.');
            console.error('Errore durante il login', error);
        }
    };

    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const btnRef = useRef(null);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (document.activeElement === inputRef1.current) {
                inputRef2.current.focus();
            }
            else if (document.activeElement === inputRef2.current) {
                btnRef.current.focus();
            }
            else if (document.activeElement === btnRef.current) {
                handleLogin();
            }
        }
    };

    return (
        <div className="login-container">
            <div className="card">
                <div className="card-body text-center">
                    <h5 className="card-title py-3">Benvenuto al casinò</h5>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            aria-label="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            ref={inputRef1}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            aria-label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            ref={inputRef2}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="d-grid mx-auto">
                        <button
                            className="btn btn-primary mb-3"
                            type="button"
                            onClick={handleLogin}
                            ref={btnRef}
                        >
                            Login
                        </button>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => navigate(`/user/Registrazione`)}
                        >
                            Registrati
                        </button>
                    </div>
                </div>
            </div>
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}
        </div>
    );
};

export default Login;
