import React from 'react';
import '../styles/guardaroba.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { aggiungiVestito, eliminaVestito } from '../api/index';

const Guardaroba = () => {
    const navigate = useNavigate();
    const { idGiocatore } = useParams();

    const [successful, setSuccessful] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
            if (error) {
                setError('');
            }
            if (successful) {
                setSuccessful('');
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [error, successful]);

    // funzione deposita vestito
    const handleDepositaVestito = async () => {
        try {
            const response = await aggiungiVestito({ idGiocatore: idGiocatore });
            if (response.status === 201) {
                setSuccessful('Vestito depositato');
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    // funzione preleva vestito
    const handlePrelevaVestito = async () => {
        try {
            const response = await eliminaVestito(idGiocatore);
            if (response.status === 200) {
                setSuccessful('Vestito prelevato');
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="container">
            <div className="left">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Home</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Bar - utente</h5>
                <div className="innerContainer">
                    <div className="center-container">
                        <div className="guardaroba-container">
                            <div className="column-container">
                                <div className="btn btn-primary deposita-vestito" onClick={() => handleDepositaVestito()}>Deposita vestito e paga</div>
                                <div className="label-guardaroba">Numero vestito: {idGiocatore}</div>
                                <div className="btn btn-primary preleva-vestito" onClick={() => handlePrelevaVestito()}>Preleva vestito</div>
                                {error && (
                                    <div className="alert alert-danger guardaroba-error-message" role="alert">
                                        {error}
                                    </div>
                                )}
                                {successful && (
                                    <div className="alert alert-success guardaroba-success-message" role="alert">
                                        {successful}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Guardaroba;
