import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/cassa-admin.css';
import { useState, useEffect } from 'react';
import { getUtenti, getBanned, banUtente, removeBan } from '../api/index';

const CassaAdmin = () => {
    const navigate = useNavigate();
    const [utenti, setUtenti] = useState([]);
    const [banned, setBanned] = useState([]);
    const [username, setUsername] = useState('');
    const [bannedUsername, setBannedUsername] = useState('');

    const handleUsername = (username) => {
        setUsername(username);
        console.log('username: ', username);
    };

    const handleBannedUsername = (username) => {
        setBannedUsername(username);
        console.log('bannedUsername: ', username);
    };

    const [successful, setSuccessful] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
            if (error) {
                setError('');
            }
            if (successful) {
                setSuccessful('');
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [error, successful]);

    const caricaUtenti = async () => {
        try {
            const response = await getUtenti();
            setUtenti(response.data);
        } catch (error) {
            setError(error.message);
        }
    }
    useEffect(() => {   
        caricaUtenti();
    }, []);

    const caricaBannati = async () => {
        try {
            const response = await getBanned();
            setBanned(response.data);
        } catch (error) {
            setError(error.message);
        }
    }
    useEffect(() => {
        caricaBannati();
    }, []);

    const banna = async () => {
        try {
            const response = banUtente(username);
            if (response.status === 200) {
                setSuccessful(response.data);
            } else {
                setError(response);
            }
        }
        catch (error) {
            setError(error.message);
        }
        caricaBannati();
    }

    const rimuoviBan = async () => {
        try {
            const response = removeBan(bannedUsername);
            console.log(response);
            if (response.status === 200) {
                setSuccessful(response.data);
            } else {
                setError(response);
            }
        }
        catch (error) {
            setError(error.message);
        }
        caricaBannati();
    }

    return (
        <div className="container">
            <div className="left">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Home</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Cassa - admin</h5>
                <div className="innerContainer">
                    <div className="column-container">
                        <div className="center-container">
                            <div className="row-container">
                                <div className="cassa-admin-left-container">
                                    <div className="column-container">
                                        <div className="cassa-admin-left-innerContainer">
                                            <div className="column-container">
                                                <div className="cassa-admin-title">Utenti registrati:</div>
                                                <div className="cassa-admin-lista-utenti">
                                                    <div className="list-group" >
                                                        {utenti.map((utenti) => (
                                                            <label className="list-group-item" key={utenti.idGiocatore}>
                                                                <input className="form-check-input me-1" type="radio" name="option" value="" onClick={() => handleUsername(utenti.username)} />
                                                                {utenti.username}
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn btn-primary banna" onClick={() => banna()}>Banna</div>
                                    </div>
                                </div>
                                <div className="cassa-admin-left-container">
                                    <div className="column-container">
                                        <div className="cassa-admin-left-innerContainer">
                                            <div className="column-container">
                                                <div className="cassa-admin-title">Black list:</div>
                                                <div className="cassa-admin-lista-utenti">
                                                    <div className="list-group" >
                                                        {banned.map((banned) => (
                                                            <label className="list-group-item" key={banned.idGiocatore}>
                                                                <input className="form-check-input me-1" type="radio" name="option" value="" onClick={() => handleBannedUsername(banned.username)} />
                                                                {banned.username}
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn btn-primary rimuovi-ban" onClick={()=> rimuoviBan()}>Rimuovi ban</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CassaAdmin;
