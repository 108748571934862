import React, { useState } from 'react';
import '../styles/login.css';
import { useNavigate } from 'react-router-dom';
import { registrazione } from '../api/index';
import bcrypt from 'bcryptjs';

const Registrazione = () => {
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [eta, setEta] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [successful, setSuccessful] = useState('');
    const navigate = useNavigate();

    const handleRegistrazione = async () => {
        try {
            const hashedPassword = await handlePassword(password);
            const response = await registrazione({ nome: nome, cognome: cognome, eta: eta, indirizzo: indirizzo, username: username, password: hashedPassword });
            console.log(response);
            setSuccessful('Registrazione effettuata');
        } catch (error) {
            setError('Errore durante la registrazione');
        }
    }

    // Funzione per criptare la password
    const handlePassword = async (password) => {
        const salt = await bcrypt.genSalt(10);
        const hashedPassword = await bcrypt.hash(password, salt);
        return hashedPassword;
    };

    return (
        <div className="login-container">
            <div className="card">
                <div className="card-body text-center">
                    <h5 className="card-title py-3">Benvenuto al casinò</h5>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome"
                            aria-label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Cognome"
                            aria-label="Cognome"
                            value={cognome}
                            onChange={(e) => setCognome(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Età"
                            aria-label="Età"
                            value={eta}
                            onChange={(e) => setEta(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Indirizzo"
                            aria-label="Indirizzo"
                            value={indirizzo}
                            onChange={(e) => setIndirizzo(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                            aria-label="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            aria-label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="d-grid mx-auto">
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleRegistrazione}
                        >
                            Registrati
                        </button>
                    </div>
                </div>
            </div>
            <div className="btn mb-3" type="button" onClick={() => navigate(`/user/Login`)}>Login</div>
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}
            {successful && (
                <div className="alert alert-success" role="alert">
                    {successful}
                </div>
            )}
        </div>
    );
};

export default Registrazione;

