import React, { useState, useEffect } from 'react';
import '../styles/god.css';
import { eliminaGiocatore, getGiocatori, getUtenti, eliminaUtente, registrazioneGod, getDrinks, deleteDrink, aggiungiDrink } from '../api/index';
import bcrypt from 'bcryptjs';

const God = () => {
    const [idGiocatore, setIdGiocatore] = useState('');
    const [listaGiocatori, setListaGiocatori] = useState([]);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [eta, setEta] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [soldi, setSoldi] = useState(0);
    const [fiches, setFiches] = useState(0);

    const [idUtente, setIdUtente] = useState('');
    const [listaUtenti, setListaUtenti] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [permessi, setPermessi] = useState('');

    const [idDrink, setIdDrink] = useState('');
    const [listaDrink, setListaDrink] = useState([]);
    const [nomeDrink, setNomeDrink] = useState('');
    const [prezzo, setPrezzo] = useState(0);

    // Funzione per caricare i giocatori
    const caricaGiocatori = async () => {
        try {
            const response = await getGiocatori();
            setListaGiocatori(response.data);
        } catch (error) {
            alert('Errore durante il caricamento dei giocatori:', error);
        }
    }

    // Funzione per caricare gli utenti
    const caricaUtenti = async () => {
        try {
            const response = await getUtenti();
            setListaUtenti(response.data);
        } catch (error) {
            alert('Errore durante il caricamento degli utenti:', error);
        }
    }

    // Funzione per caricare i drink
    const caricaDrinks = async () => {
        try {
            const response = await getDrinks();
            setListaDrink(response.data);
        } catch (error) {
            alert('Errore durante il caricamento dei drink:', error);
        }
    }

    // Carica i giocatori e gli utenti al caricamento della pagina
    useEffect(() => {
        caricaUtenti();
        caricaGiocatori();
        caricaDrinks();
    }, []);

    // Funzione per registrare un utente
    const handleRegistrazione = async () => {
        try {
            const hashedPassword = await handlePassword(password);
            const response = await registrazioneGod({ nome, cognome, eta, indirizzo, soldi, fiches, username, password: hashedPassword, permessi });
            if (response.status === 200) {
                alert('Utente registrato con successo');
                caricaUtenti();
                caricaGiocatori();
            }
        } catch (error) {
            alert('Errore durante la registrazione dell\'utente:', error);
        }
    }

    // Funzione per eliminare un giocatore
    const handleEliminaGiocatore = async () => {
        try {
            const response = await eliminaGiocatore(idGiocatore);
            if (response.status === 200) {
                alert('Giocatore eliminato con successo');
                caricaGiocatori();
            }
        } catch (error) {
            alert('Errore durante l\'eliminazione del giocatore: eliminare dalla tabella utente', error);
        }
    }

    // Funzione per eliminare un utente
    const handleEliminaUtente = async () => {
        try {
            const response = await eliminaUtente(idUtente);
            if (response.status === 200) {
                alert('Utente eliminato con successo');
                caricaUtenti();
            }
        } catch (error) {
            alert('Errore durante l\'eliminazione dell\'utente:', error);
        }
    }

    // Funzione per eliminare un drink
    const handleEliminaDrink = async () => {
        try {
            const response = await deleteDrink(idDrink);
            if (response.status === 200) {
                alert('Drink eliminato con successo');
                caricaDrinks();
            }
        } catch (error) {
            alert('Errore durante l\'eliminazione del drink:', error);
        }
    }

    // Funzione per aggiungere un drink
    const handleAggiungiDrink = async () => {
        try {
            const response = await aggiungiDrink({ nome: nomeDrink, prezzo: prezzo });
            if (response.status === 200) {
                alert('Drink aggiunto con successo');
                caricaDrinks();
            }
        } catch (error) {
            alert('Errore durante l\'aggiunta del drink:', error);
        }
    }

    // Funzione per criptare la password
    const handlePassword = async (password) => {
        const salt = await bcrypt.genSalt(10);
        const hashedPassword = await bcrypt.hash(password, salt);
        return hashedPassword;
    };

    return (
        <div className="container">
            <div className="center-container">
                <div className="god-container">
                    <div className="column-container">

                        {/* Titolo */}
                        <div className="god-title">Pannello di controllo</div>

                        {/* Card Giocatori */}
                        <div className="god-card god-card-giocatore">
                            <div className="row-container">
                                <div className="container-list god-container-list">
                                    <div className="list-title">Lista giocatori</div>
                                    <div className="list-box-container">
                                        <div className="list-group">
                                            {listaGiocatori.map((listaGiocatori) => (
                                                <label className="list-group-item" key={listaGiocatori.id}>
                                                    <input className="form-check-input me-1" type="radio" name="option" value="" onClick={() => setIdGiocatore(listaGiocatori.id)} />
                                                    Id: {listaGiocatori.id} - {listaGiocatori.nome} {listaGiocatori.cognome}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="god-form-container">
                                    <div className="column-container">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control god-form-control"
                                                placeholder="Nome"
                                                aria-label="Nome"
                                                value={nome}
                                                onChange={(e) => setNome(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control god-form-control"
                                                placeholder="Cognome"
                                                aria-label="Cognome"
                                                value={cognome}
                                                onChange={(e) => setCognome(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control god-form-control"
                                                placeholder="Età"
                                                aria-label="Età"
                                                value={eta}
                                                onChange={(e) => setEta(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control god-form-control"
                                                placeholder="Indirizzo"
                                                aria-label="Indirizzo"
                                                value={indirizzo}
                                                onChange={(e) => setIndirizzo(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control god-form-control"
                                                placeholder="Soldi"
                                                aria-label="Soldi"
                                                value={soldi}
                                                onChange={(e) => setSoldi(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control god-form-control"
                                                placeholder="Fiches"
                                                aria-label="Fiches"
                                                value={fiches}
                                                onChange={(e) => setFiches(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control god-form-control"
                                                placeholder="Username"
                                                aria-label="Username"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="password"
                                                className="form-control god-form-control"
                                                placeholder="Password"
                                                aria-label="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control god-form-control"
                                                placeholder="Permessi"
                                                aria-label="Permessi"
                                                value={permessi}
                                                onChange={(e) => setPermessi(e.target.value)}
                                            />
                                        </div>
                                        <div
                                            className="btn btn-primary god-card-btn"
                                            onClick={() => handleEliminaGiocatore()}
                                        >
                                            Elimina giocatore
                                        </div>
                                        <div
                                            className="btn btn-primary god-card-btn"
                                            onClick={() => handleRegistrazione()}
                                        >
                                            Registra giocatore
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Card Utenti */}
                        <div className="god-card god-card-utenti">
                            <div className="column-container">
                                <div className="container-list god-container-list">
                                    <div className="list-title">Lista utenti</div>
                                    <div className="list-box-container">
                                        <div className="list-group">
                                            {listaUtenti.map((listaUtenti) => (
                                                <label className="list-group-item" key={listaUtenti.idGiocatore}>
                                                    <input className="form-check-input me-1" type="radio" name="option" value="" onClick={() => setIdUtente(listaUtenti.idGiocatore)} />
                                                    Id: {listaUtenti.idGiocatore} - {listaUtenti.username}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="god-form-container">
                                    <div className="column-container">
                                        <button
                                            className="btn btn-primary god-card-btn-elimina-utente"
                                            type="button"
                                            onClick={() => handleEliminaUtente()}
                                        >
                                            Elimina utente
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Card Drink */}
                        <div className="god-card god-card-drink">
                            <div className="row-container">
                                <div className="container-list god-container-list">
                                    <div className="list-title">Lista drink</div>
                                    <div className="list-box-container">
                                        <div className="list-group">
                                            {listaDrink.map((listaDrink) => (
                                                <label className="list-group-item" key={listaDrink.nome}>
                                                    <input className="form-check-input me-1" type="radio" name="option" value="" onClick={() => setIdDrink(listaDrink.nome)} />
                                                    {listaDrink.nome} - {listaDrink.prezzo} €
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="god-form-container">
                                    <div className="column-container">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control god-form-control"
                                                placeholder="Nome drink"
                                                aria-label="Nome drink"
                                                onChange={(e) => setNomeDrink(e.target.value)}
                                            />
                                        </div>

                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control god-form-control"
                                                placeholder="Prezzo"
                                                aria-label="Prezzo"
                                                onChange={(e) => setPrezzo(e.target.value)}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-primary god-card-btn"
                                            type="button"
                                            onClick={() => handleEliminaDrink()}
                                        >
                                            Elimina drink
                                        </button>
                                        <button
                                            className="btn btn-primary god-card-btn"
                                            type="button"
                                            onClick={() => handleAggiungiDrink()}
                                        >
                                            Crea drink
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Spazio 20px */}
                        <div className="spazio-20"><p></p></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default God;

