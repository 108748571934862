import axios from 'axios'

const api = axios.create({
    baseURL: 'https://5aisamuelegallina.barsanti.edu.it/api',
})

// login 
export const login = payload => api.post(`/login`, payload)

// registrazione
export const registrazione = payload => api.post(`/registrazione`, payload)
export const registrazioneGod = payload => api.post(`/registrazioneGod`, payload)

// tabella presenze
export const salvaPresenza = payload => api.post(`/salva-presenza`, payload)
export const updatePresenza = payload => api.post(`/aggiorna-presenza`, payload)

// tabella giocatore
export const getGiocatori = () => api.get(`/getGiocatori`)
export const getGiocatoreById = idGiocatore => api.get(`/giocatore/${idGiocatore}`)
export const updateGiocatore = payload => api.put(`/aggiorna-giocatore`, payload)
export const paga = payload => api.post(`/paga`, payload)
export const aggiungiSoldi = payload => api.post(`/aggiungiSoldi`, payload)
export const pagaFiches = payload => api.post(`/pagaFiches`, payload)
export const aggiungiFiches = payload => api.post(`/aggiungiFiches`, payload)
export const creaGiocatore = payload => api.post(`/creaGiocatore`, payload)
export const eliminaGiocatore = idGiocatore => api.delete(`/eliminaGiocatore/${idGiocatore}`)

// tabella utente
export const aggiornaPassword = payload => api.post(`/aggiorna-password`, payload)
export const getUtenti = () => api.get(`/getUtenti`)
export const getBanned = () => api.get(`/getBanned`)
export const banUtente = username => api.post(`/banUtente/${username}`)
export const removeBan = username => api.post(`/rimuovibanUtente/${username}`)
export const eliminaUtente = idGiocatore => api.delete(`/eliminaUtente/${idGiocatore}`)

// tabella drink
export const getDrinks = () => api.get(`/getDrinks`)
export const deleteDrink = nome => api.delete(`/eliminaDrink/${nome}`)
export const updateDrink = payload => api.put(`/aggiornaDrink`, payload)
export const aggiungiDrink = payload => api.post(`/aggiungiDrink`, payload)

// tabella guardaroba
export const getVestiti = () => api.get(`/getVestiti`)
export const aggiungiVestito = payload => api.post(`/aggiungiVestito`, payload)
export const eliminaVestito = idGiocatore => api.delete(`/eliminaVestito/${idGiocatore}`)

const apis = {
    login,
    registrazione,
    salvaPresenza,
    updatePresenza,
    getGiocatoreById,
    updateGiocatore,
    paga,
    aggiungiSoldi,
    pagaFiches,
    aggiungiFiches,
    aggiornaPassword,
    getUtenti,
    getBanned,
    banUtente,
    removeBan,
    getDrinks,
    deleteDrink,
    updateDrink,
    aggiungiDrink,
    getVestiti,
    aggiungiVestito,
    eliminaVestito
}

export default apis
