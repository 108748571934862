import React from 'react';
import '../styles/guardaroba-admin.css';
import { useNavigate } from 'react-router-dom';
import { getVestiti, aggiungiVestito, eliminaVestito } from '../api/index';
import { useState, useEffect } from 'react';

const GuardarobaAdmin = () => {
    const navigate = useNavigate();
    const [vestiti, setVestiti] = useState([]);
    const [selected, setSelected] = useState('');
    const [daEleiminare, setDaEliminare] = useState([]);
    const [successful, setSuccessful] = useState('');
    const [error, setError] = useState('');

    const handleDaEliminare = (id) => {
        setDaEliminare(id);
        console.log('daEliminare: ', daEleiminare);
    }
    
    useEffect(() => {
        const timer = setTimeout(() => {
            if (error) {
                setError('');
            }
            if (successful) {
                setSuccessful('');
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [error, successful]);

    const caricaVestiti = async () => {
        try {
            const response = await getVestiti();
            setVestiti(response.data);
        } catch (error) {
            setError(error.message);
        }
    }
    useEffect(() => {
        caricaVestiti();
    }, []);

    const aggiungi = async () => {
        try {
            const response = await aggiungiVestito({ idGiocatore: selected });
            if (response.status === 201) {
                setSuccessful('Vestito aggiunto');
                caricaVestiti();
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError(error.message);
        }
    }

    const elimina = async () => {
        try {
            const response = await eliminaVestito(daEleiminare);
            setSuccessful(response.data.message);
            caricaVestiti();
        } catch (error) {
            setError(error.message);
        }
    }

    return (
        <div className="container">
            <div className="left">
                <button className="btn btn-primary" onClick={() => navigate(-1)}>Home</button>
            </div>
            <div className="outerContainer">
                <h5 className="outerContainer-title">Guardaroba - admin</h5>
                <div className="innerContainer-bar">
                    <div className="row-container">
                        <div className="container-list">
                            <div className="column-container">
                                <div className="list-title">Lista vestiti</div>
                                <div className="list-box-container">
                                    <div className="list-group" >
                                        {vestiti.map((vestiti) => (
                                            <label className="list-group-item" key={vestiti.idProprietario}>
                                                <input className="form-check-input me-1" type="radio" name="option" value="" onClick={() => handleDaEliminare(vestiti.idProprietario)} />
                                                {vestiti.idProprietario} - {vestiti.username}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-right-bar">
                            <div className="column-container">
                                <div className="container-ordinazione-guardaroba-admin">
                                    <div className="list-title">Inserire id utente</div>
                                    <input
                                        type="number"
                                        className="form-control form-control-guardaroba-admin"
                                        placeholder="Id utente"
                                        onChange={(e) => setSelected(e.target.value)}
                                    />
                                    {error && (
                                        <div className="alert alert-danger bar-error-message" role="alert">
                                            {error}
                                        </div>
                                    )}
                                    {successful && (
                                        <div className="alert alert-success bar-success-message" role="alert">
                                            {successful}
                                        </div>
                                    )}
                                </div>
                                <div className="btn btn-primary guardaroba-admin-aggiungi" onClick={() => aggiungi()}>Aggiungi</div>
                                <div className="btn btn-primary guardaroba-admin-elimina" onClick={() => elimina()}>Elimina</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuardarobaAdmin;
